import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMenu } from '../../../models/interfaces/menu.interface';
import { MenuService } from '../../../services/menu/menu.service';
import { LoggerService } from '../../../services/logger/logger.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  idLog: string = 'NavbarComponent';
  menus: IMenu[] = [];
  selectedMenu: number = -1;
  state: boolean = true;
  @Output() clickGoToTop: EventEmitter<any> = new EventEmitter();

  constructor(
    private menuService: MenuService,
    private logger: LoggerService
  ) { }

  ngOnInit() {
    this.menuService.getMenu()
      .subscribe(response => {
        this.menus = response;
        // this.logger.log(this.idLog, 'ngOnInit - getMenu', { info: 'Success', response })
      }, error => {
        this.logger.error(this.idLog, 'ngOnInit - getMenu', { info: 'Error', error })
      })
    this.menuService.getSelectedMenu()
      .subscribe(response => {
        this.selectedMenu = response;
        // this.logger.log(this.idLog, 'ngOnInit - getSelectedMenu', { info: 'Success', response })
      }, error => {
        this.logger.error(this.idLog, 'ngOnInit - getSelectedMenu', { info: 'Error', error })
      })
    this.menuService.getState()
      .subscribe(response => {
        this.state = response;
        // this.logger.log(this.idLog, 'ngOnInit - getState', { info: 'Success', response })
      }, error => {
        this.logger.error(this.idLog, 'ngOnInit - getState', { info: 'Error', error })
      })
  }

  goToTop() {
    this.clickGoToTop.emit()
  }
}
