import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PoliticsEasyCookComponent } from './components/pages/politics-easy-cook/politics-easy-cook.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'portafolio',
    loadChildren: () => import('./components/pages/portafolio/portafolio.module').then(m => m.PortafolioModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'store',
    loadChildren: () => import('./components/pages/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'certificaciones',
    loadChildren: () => import('./components/pages/certifications/certifications.module').then(m => m.CertificationsModule)
  },
  {
    path: 'politics/easy-cook',
    component: PoliticsEasyCookComponent
  },
  // { path: '', component: HomeComponent },
  // { path: 'portafolio', component: PortafolioComponent },
  // { path: 'contacto', component: ContactComponent },
  // { path: 'blog', component: BlogComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
