import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMenu } from '../../models/interfaces/menu.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  selectedMenu: BehaviorSubject<any> = new BehaviorSubject(null);
  menuList: BehaviorSubject<IMenu[]> = new BehaviorSubject([]);
  state: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() { }

  setSelectedMenu(menu: number) {
    this.selectedMenu.next(menu)
  }

  getSelectedMenu() {
    return this.selectedMenu.asObservable()
  }

  setMenu(menus: IMenu[]) {
    this.menuList.next(menus)
  }

  getMenu() {
    return this.menuList.asObservable()
  }

  setState(state: boolean) {
    this.state.next(state)
  }

  getState() {
    return this.state.asObservable()
  }
}
