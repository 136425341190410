import { Component, HostListener } from '@angular/core';
import { IMenu } from './models/interfaces/menu.interface';
import { MenuService } from './services/menu/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'myPage';
  menus: IMenu[] = [
    {
      text: 'Inicio',
      router: '/'
    },
    {
      text: 'Portafolio',
      router: '/portafolio'
    },
    {
      text: 'Contacto',
      router: '/contacto'
    },
    {
      text: 'Mis Apps',
      router: '/store'
    },
    {
      text: 'Certificaciones',
      router: '/certificaciones'
    }
  ]
  constructor(
    private menuService: MenuService
  ) {
    this.menuService.setMenu(this.menus)
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20) {
      this.menuService.setState(false)
    } else {
      this.menuService.setState(true)
    }
  }



  ngOnInit() {
  }
}
