import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politics-easy-cook',
  templateUrl: './politics-easy-cook.component.html',
  styleUrls: ['./politics-easy-cook.component.scss']
})
export class PoliticsEasyCookComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.getElementById('mainNav').classList.add('hide')
  }

}
